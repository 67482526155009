<template>
    <div class="main">
        <h1>Job Description</h1>
        <router-link to="/careers">Back to Careers</router-link>
    </div>
</template>

<script>
export default {
    name: 'JobDescription'
}
</script>

<style scoped>
.main {
    width: 100%;
}
</style>