<template>
    <div class="container">
        <h1>Update Developers Data</h1>
    </div>
</template>

<script>
export default{
    name:'AdminDevelopers'
}
</script>