<template>
    <div  style="width: 100%;">
        <h1>Page Not Found</h1>
        <router-link to="/">Back To Home Page</router-link>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound'
}
</script>