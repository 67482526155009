<template>
    <div class="container">
        <h1>User Management</h1>
    </div>
</template>

<script>
export default{
    name:'AdminUm'
}
</script>