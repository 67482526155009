<template>
    <div class="container">
        <h1>Update Careers Page</h1>
    </div>
</template>

<script>
export default{
    name:'AdminCareers'
}
</script>

<style scoped>
.container{
    background-color: aqua;
}
</style>